import React, { lazy } from 'react';
import styled, { css } from 'styled-components';

import { getComponentSettings, removeSpecialCharacters } from 'libs/content';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import DownloadApp from 'components/mitt-nte/DownloadApp';
import Ratings from 'components/mitt-nte/Ratings';
import Spacing from 'layouts/Spacing';
import MaxWidth from 'layouts/max-width';
import SponsorshipSteps from 'components/sponsorship/SponsorshipSteps';
import StreamingServices from './StreamingServices';
import Staircase from './Staircase';
import Fordelsprogram from 'components/fordelsprogram/Fordelsprogram';
import FiberDeliveryCheck from 'components/fiber-2.0/FiberDeliveryCheck';
import ReferralHero from 'components/strombestilling/components/ReferralHero';
import SponsorshipsMap from 'components/sponsorship/SponsorshipsMap';
import DepartmentsMap from 'components/elektro/DepartmentsMap';
const HydrogenMap = lazy(() =>
	import('components/bedrift/HydrogenDepartmentsMap')
);

const Wrapper = styled.div`
	text-align: ${p => (p.$centered === 'true' && 'center') || 'left'};
	.max-width {
		.max-width {
			padding: 0;
		}
		.power-referral-hero .max-width {
			padding: 0 10px;
		}
	}

	${p =>
		p.$componentindex === 0 &&
		css`
			> .spacing {
				margin-top: 0 !important;
			}
		`}
`;

export default function Miscellaneous({
	title,
	intro = {},
	headinglevel = 'h2',
	component,
	settings,
	...props
}) {
	const componentSettings = getComponentSettings({ settings, props });

	// Outer wrapper component, max width if title or text exists
	const WrapperComponent =
		componentSettings?.hidetitle !== 'true' && !!(title || intro?.raw)
			? MaxWidth
			: 'div';

	// Components that should be full width
	const fullWidthComponents = [
		'Elektriker - Avdelingskart',
		'Mitt NTE - Nedlastingsknapper',
		'Telekom - Sjekk om du kan få fiber',
		'Strøm - Verving - Toppbanner og produkt',
		'Sponsing - Kart',
	];

	// If the component is full width, use a div instead of MaxWidth
	const MaxWidthWrapper = fullWidthComponents.includes(component)
		? 'div'
		: MaxWidth;

	if (!component) return null;

	return (
		<Wrapper
			className={`component__miscellaneous component__miscellaneous--${removeSpecialCharacters(
				component
			)}`}
			data-cy="component__miscellaneous"
			$centered={componentSettings?.centered}
			$componentindex={props?.componentindex}>
			<Spacing {...props}>
				<WrapperComponent
					className={
						(componentSettings?.hidetitle !== 'true' &&
							!!(title || intro?.raw) &&
							'max-width') ||
						'full-width'
					}>
					{componentSettings?.hidetitle !== 'true' && (
						<TitleAndText
							title={title}
							text={intro?.raw}
							settings={settings}
							headinglevel={headinglevel}
							transitions={props?.transitions}
							nested={true}
						/>
					)}

					<MaxWidthWrapper className="max-width">
						<ComponentVariant
							type={component}
							title={title}
							text={intro}
							headinglevel={headinglevel}
							settings={componentSettings}
							{...props}
						/>
					</MaxWidthWrapper>
				</WrapperComponent>
			</Spacing>
		</Wrapper>
	);
}

/**
 * Component for rendering a specific variant of a component.
 * @param {Object} props - The props object for the ComponentVariant component.
 * @param {string} props.type - The type of the component.
 * @param {string} props.title - The title of the component.
 * @param {Object} props.settings - The settings for the component.
 * @param {Object} props.props - The rest of the props for the component.
 * @returns {JSX.Element} The component variant element.
 */
function ComponentVariant({ type = '', title = '', settings = {}, ...props }) {
	if (!type) return null;

	// Show title if not hidden and if it exists
	const showTitle = !settings?.hidetitle ? title : '';

	/**
	 * Types: (from CMS)
	 * Mitt NTE - Nedlastingsknapper
	 * Mitt NTE - Ratings
	 * Strøm - Verving - Toppbanner og produkt
	 * Telekom - TV og strømmetjenester
	 * Telekom - Sjekk om du kan få fiber
	 * Elektriker - Avdelingskart
	 * Bedrift - Strøm - Energitrappa
	 * Bedrift - Hydrogen - Anleggskart
	 * Sponsing - Slik fungerer det
	 * Sponsing - Kart
	 * Om NTE - Fordelsprogram
	 */

	// Mitt NTE Download buttons
	if (type === 'Mitt NTE - Nedlastingsknapper') {
		return <DownloadApp {...settings} />;
	}

	// Mitt NTE Ratings
	if (type === 'Mitt NTE - Ratings') {
		return <Ratings {...settings} />;
	}

	// Telekom - TV og strømmetjenester
	if (type === 'Telekom - TV og strømmetjenester') {
		return <StreamingServices {...settings} />;
	}

	// Telekom - Sjekk om du kan få fiber
	if (type === 'Telekom - Sjekk om du kan få fiber') {
		return (
			<FiberDeliveryCheck
				showResults={props?.componentindex <= 1}
				{...props}
			/>
		);
	}

	// Elektriker - Avdelingskart
	if (type === 'Elektriker - Avdelingskart') {
		return <DepartmentsMap headinglevel={props?.headinglevel} />;
	}

	// Bedrift - Hydrogen - Anleggskart
	if (type === 'Bedrift - Hydrogen - Anleggskart') {
		return <HydrogenMap />;
	}

	// Sponsing - Slik fungerer det
	if (type === 'Sponsing - Slik fungerer det') {
		return (
			<SponsorshipSteps
				title={showTitle || 'Slik fungerer det'}
				showFirstStep={false}
			/>
		);
	}

	// Bedrift - Strøm - Energitrappa
	if (type === 'Bedrift - Strøm - Energitrappa') {
		return <Staircase title={showTitle || 'Energitrappa'} {...settings} />;
	}

	// Om NTE - Fordelsprogram
	if (type === 'Om NTE - Fordelsprogram') {
		return (
			<Fordelsprogram
				offers={props?.offers}
				location={props?.pagesettings}
				{...settings}
			/>
		);
	}

	// Strøm - Verving - Toppbanner og produkt
	if (type === 'Strøm - Verving - Toppbanner og produkt') {
		return (
			<ReferralHero
				title={title}
				text={props?.text}
				location={props?.pagesettings}
				{...settings}
			/>
		);
	}

	// Sponsing - Kart
	if (type === 'Sponsing - Kart') {
		return (
			<SponsorshipsMap
				title={title}
				{...settings}
				headinglevel={props?.headinglevel}
			/>
		);
	}

	return <></>;
}
